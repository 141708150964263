import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div id="main-nav" className="navigation animated">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-4">
              <Link className="text-white" to="/" title="Logo">
                <h3>EthosOpp</h3>
              </Link>
            </div>
            <div className="col-6 col-md-8">
              <div className="nav-right d-flex align-items-center">
                {/*                <div className="social mr-2">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>*/}
                <div className="cta text-left mr-2 align-self-center">
                  <a
                    className="btn-sm btn-lg btn-outline-white"
                    href="/#contact"
                  >
                    <i className="fa fa-phone" />
                    &nbsp;Contact us
                  </a>
                </div>
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
