import React from 'react'
import MySwiper from './Hero'
import { Row, Col } from 'react-bootstrap'

class Hero extends React.Component {
	render() {
		const { location, title } = this.props
		const rootPath = `${__PATH_PREFIX__}/`
		let hero
		if (location.pathname === rootPath) {
			hero = (
				<hero>
					<div id="banner" className="simple-slider">
						<MySwiper>
							<div
								className="swiper-slide"
								style={{ backgroundImage: `url(/img/hero-bg-3.jpg)` }}
							>
								<div className="jumbotron">
									<h1>
										You do what you do best,
										<br />
										Let us do what we do best.
									</h1>
									{/*<p className="mt-4">
										<a
											className="btn btn-primary btn-lg btn-outline-white"
											role="button"
										>
											Schedule now
										</a>
									</p>*/}
								</div>
							</div>

							<div
								className="swiper-slide"
								style={{ backgroundImage: `url(/img/hero-bg-2.jpg)` }}
							>
								<div className="jumbotron">
									<h1>
										We improve your bottom line,
										<br />
										By taking care of your front line.
									</h1>
									{/*<p className="mt-4">
										<a
											className="btn btn-primary btn-lg btn-outline-white"
											role="button"
										>
											Schedule now
										</a>
									</p>*/}
								</div>
							</div>
							<div
								className="swiper-slide"
								style={{ backgroundImage: `url(/img/hero-bg-1.jpg)` }}
							>
								<div className="jumbotron">
									<h1>
										Think Different, Hire Different,
										<br />
										Be Different.
									</h1>
									{/*<p className="mt-4">
										<a
											className="btn btn-primary btn-lg btn-outline-white"
											role="button"
										>
											Schedule now
										</a>
									</p>*/}
								</div>
							</div>
						</MySwiper>
					</div>
				</hero>
			)
		} else {
			hero = (
				<hero>
					<div className="hero-bg-page">
						<div className="hero-main-banner">
							<div className="container">
								<Row>
									<Col lg={8} md={9}>
										<h1 className="text-white">{title}</h1>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</hero>
			)
		}
		return <>{hero}</>
	}
}

export default Hero
