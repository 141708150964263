import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import './menu.css'

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item className="text-white mb-3" to="/">
        <Link to="/" title="Logo">
          <h3>EthosOpp</h3>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/" title="Home">
          Home
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us" title="About">
          About
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/#services" title="Services">
          Services
        </Link>
      </Nav.Item>

      <Nav.Item>
        <Link to="/#contact" title="Contact Us">
          Contact Us
        </Link>
      </Nav.Item>
    </Nav>
    <div className="social sidemenu-social mr-2">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/ethosoppjobs"
            title="EthosOpp Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/ethosoppjobs/"
            title="EthosOpp instagram"
          >
            <i
              className="fa fa-instagram"
              target="_blank"
              rel="noopener noreferrer"
            />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/eo-ethosopp/"
            title="EthosOpp Linkedin"
          >
            <i
              className="fa fa-linkedin"
              target="_blank"
              rel="noopener noreferrer"
            />
          </a>
        </li>
      </ul>
    </div>
    <p className="mt-4 card-text text-white">
      All Rights Reserved, <br /> EthosOpp © {new Date().getFullYear()}
    </p>
  </div>
)
