import React from 'react'
import Form from './Form'

const Footer = () => (
  <footer>
    <div id="contact" className="contact-clean">
      <div className="container">
        <div className="title text-center" />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="contact-details">
              <h2>Contact Us</h2>
              <h3 className="subtitle">We'd love to hear from you</h3>
              <ul className="contact-info">
                <li>
                  <div className="contact-icon">
                    <i className="fa fa-location-arrow" />
                  </div>
                  <div className="contact-text">
                    <span>Address</span>
                    <p>Denver, CO</p>
                  </div>
                </li>
                <li>
                  <a
                    href="tel:303-512-3545"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="phone"
                  >
                    <div className="contact-icon">
                      <i className="fa fa-phone" />
                    </div>
                  </a>
                  <div className="contact-text">
                    <span>contact us</span>
                    <p>
                      <a
                        href="tel:303-512-3545"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="phone"
                      >
                        303-512-3545
                      </a>
                    </p>
                  </div>
                </li>
                <li>
                  <a
                    href="mailto:info@ethosopp.com"
                    target="_blank"
                    title="email"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                    <div className="contact-icon">
                      <i className="fa fa-envelope" />
                    </div>
                  </a>
                  <div className="contact-text">
                    <a
                      href="mailto:info@ethosopp.com"
                      target="_blank"
                      title="email"
                      rel="noopener noreferrer"
                    >
                      <span>Email</span>
                    </a>
                    <p>
                      <a
                        href="mailto:info@ethosopp.com"
                        target="_blank"
                        title="email"
                        rel="noopener noreferrer"
                      >
                        info@ethosopp.com
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <Form
              name="Contact Us"
              form_id="5d414ffcd109b934fafe0916"
              form_classname="contact us"
              method="POST"
            >
              <div className="contact-img mb-4">
                <img
                  className="img-fluid comp-logo"
                  src="/img/eo-logo.jpg"
                  alt="Ethos Opp"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name" className="d-none">
                  Name{' '}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name"
                  id="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone" className="d-none">
                  Phone
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="Phone"
                  placeholder="Phone"
                  id="phone"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email" className="d-none">
                  Email
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  id="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message" className="d-none">
                  Message
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Message"
                  rows="14"
                  id="message"
                />
              </div>
              <div className="form-group">
                <div className="webriq-recaptcha" />
              </div>
              <div className="form-group">
                <button className="btn btn-primary submit" type="submit">
                  send{' '}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-basic">
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright">
                EthosOpp © {new Date().getFullYear()} &nbsp;Designed & Powered
                by&nbsp;
                <a href="https://www.webriq.com/">WebriQ</a>
              </p>
            </div>
            <div className="col">
              {/*              <div className="social">
                <a
                  href="#"
                  title="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
                <a
                  href="#"
                  title="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin" />
                </a>
                <a
                  href="#"
                  title="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href="#"
                  title="youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              </div>*/}
            </div>
          </div>
        </div>
      </footer>
    </div>
  </footer>
)

export default Footer
