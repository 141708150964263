import React from 'react'
import Swiper from 'react-id-swiper'
// import 'react-id-swiper/lib/styles/css/swiper.css'
import './assets/swiper.min.css'

class SwiperSample extends React.Component {
  render() {
    const { children } = this.props
    const params = {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.swiper-button-next.next',
        prevEl: '.swiper-button-prev.prev',
      },
    }
    return <Swiper {...params}>{children}</Swiper>
  }
}

export default SwiperSample
