import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.css'
import HeroSwiper from '../components/HeroSwiper'
import Header from './Header'
import Footer from './Footer'
import './styles.css'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props

    return (
      <div>
        <Header />
        <HeroSwiper location={location} title={title} />

        {children}

        <Footer />
      </div>
    )
  }
}

export default Layout
